var Media = {
	
	initSimple: function(groupId) {
		if (groupId === undefined)
			groupId = "media-items";
		this.init(null,null,null,null,groupId,groupId);
	},
	init: function(itemId, itemIdField, model, controller, videoContainerId, photoContainerId) {
		var me = this;
		this.model = model;
		
		
		if (videoContainerId===undefined)
			videoContainerId = "sendage_media_videos";
		if (photoContainerId===undefined)
			photoContainerId = "sendage_media_photos";	
	
		$("#media_link").val("http://").focus(function(){
			if ($(this).val()=="http://")
				$(this).val("");
		});
		
		$("#media_submit").button().click(function() {
			var url = $.trim($("#media_link").val());
			$("#media_link").val("http://");	
			$("#media_attacher sup.supported").css("color", "");
			$("#media_attacher div.media-error").remove();

			if (url.substr(0,7) == "http://")
				url = url.substr(7);
				else if (url.substr(0,8) == "https://")
					url = url.substr(8);
	
			var testurl = url;
			if (testurl.substr(0,4) == "www.")
				testurl = testurl.substr(4);
	
			var isYoutube = testurl.indexOf("youtube.com") == 0;
			var isVimeo = testurl.indexOf("vimeo.com") == 0;
//			var isFlickr = testurl.indexOf("flickr.com") == 0;
			var isPicasa = testurl.indexOf("picasaweb.google.com") == 0;
	
			if (isYoutube || isVimeo/* || isFlickr || isPicasa*/)
			{
				var data = {data:{}};
				data.data[model] = {};
				data.data[model]["url"] = "http://"+url;
				if (itemId)
					data.data[model][itemIdField] = itemId;
				
				//var providerURL = isPicasa ? "http://api.embed.ly/1/oembed?" : null;
				var providerURL = null;
				
				$.ajax({
					url: "/"+controller+"/attach",
					dataType: "json",
					type: "POST",
					data: data,
					success: function(data) {
						if (data)
						{
							var type = data[model].type;
							var container = $("<div class='sendage-media'></div>").prependTo(type=="video"?$("#"+videoContainerId):$("#"+photoContainerId));
							setupMedia(data[model].id, data[model].url, false, container, videoContainerId, photoContainerId, providerURL);
						}	
						else
						{
							$("#media_attacher sup.supported").append("<div class='media-error'>This media is already attached.</div>");
						}				
					}
				});
			}
			else
			{
				$("#media_attacher sup.supported").css("color", "red");
			}
			
		});
		
		var $mediaUploader = $("#media_uploader");
		
		if ($mediaUploader.length)
		{
			var $mediaUploaderButton = $("#media_uploader button");
			//$("#photo_upload_button").button();
			$mediaUploaderButton.button({
				icons: {
					primary: 'ui-icon-image'
				}
			});
			
			$("#qq_upload_button").hover(function() {
				$mediaUploaderButton.addClass("ui-state-hover");
			}, function() {
				$mediaUploaderButton.removeClass("ui-state-hover");
			});
			
			var dataType = $mediaUploader.attr("data-type");
			var dataItemId = $mediaUploader.attr("data-item-id");
			var newUploadContainer;
			
			var uploader = new qq.FileUploaderBasic({
				action: "/"+dataType+"_media/upload"+window.location.pathname+"/"+dataItemId,
				button: $("#qq_upload_button")[0],
				multiple: false,
				onSubmit: function(id, fileName) {
					// show progress bar
					newUploadContainer = $("<div class='sendage-media'></div>").prependTo($("#"+photoContainerId));
					newUploadContainer.append("<div class='sendage-media-item inprogress'></div>");
				},				
				onComplete: function(id, fileName, data) {
					if (data && !data.error)
					{
						newUploadContainer.empty();
						setupLocalMedia(data[model].id, data[model].url, '', newUploadContainer, videoContainerId, photoContainerId);
					}
					else if (data.error)
					{
						newUploadContainer.remove();
						alert(data.error);
						
					}
				},
				
				// override to show messages with some nice UI
				showMessage: function(message) {
					//alert(message);
				}               
			}); 
		}		
		
		
		var numMedia = $(".sendage-media a").length;
		 
		$(".sendage-media a").each(function(i,e) {
			var anchor = $(this);
			var parent = anchor.parent();
			parent.empty();
			
			var group = false;
			
			if (anchor.attr("rel"))
				group = anchor.attr("rel");

			var href = anchor.attr("href");
			
			if (href.match(/^http/))
				setupMedia(anchor.attr("mediaId"), href, anchor.attr("title"), parent, group?group:videoContainerId, group?group:photoContainerId, null, anchor.attr("cache"));		
			else if (href.match(/^\/img/))
				setupLocalMedia(anchor.attr("mediaId"), href, anchor.attr("title"), parent, group?group:videoContainerId, group?group:photoContainerId);
		});
			
		function getColorboxHtml(title, content, url) {
			var html = [];
			html.push('<div class="comment_container">');
			html.push('<div class="content">'+content+'</div>');
			html.push('<div class="comments">');
			html.push('<div class="meta">');
			if (title) {
				html.push('<h3>'+title+'</h3>');
			}
			html.push('<a href="'+url+'" target="_blank">View Original</a><div class="clear"></div>')
			html.push('</div></div>');
			html.push('</div>');
			return html.join('');
		}
		
		function getColorboxSettings(title, content, url, type) {
			var settings = {
				fixed: true,
				width: "80%",
				height: "95%",
				html: getColorboxHtml(title, content, url),
				preloading: false,
				onOpen: function() {
					var pageComments = $("#page_comments").find("#disqus_thread");
					if (pageComments.length) {
						pageComments.remove();
					} 
					
					$("body").css("overflow", "hidden");

					/*
					if (window.DISQUS) {
						DISQUS.reset({
							reload: false,
							config: function () {  
								this.callbacks.onNewCommentOriginal = this.callbacks.onNewComment;
								this.callbacks.onNewComment = [];
						  }
						});
					}
					*/

				},
				// Closes
				onCleanup: function() {
					var pageComments = $("#page_comments");
					if (pageComments.length) {
						pageComments.append($("<div id='disqus_thread'></div>"));
						
						DISQUS.reset({
						  reload: true,
						  config: function () {  
						    //this.page.identifier = document.location.href;  
						    this.page.url = document.location.href;
						    if (this.callbacks.onNewCommentOriginal) {
						    	this.callbacks.onNewComment = this.callbacks.onNewCommentOriginal;	
						    }
							
						  }
						});
					}
					$("body").css("overflow", "auto");							
				},
				onLoad: function() {
					$(".comment_container").find("#disqus_thread").remove();
				},
				onComplete: function() {
					$(".comment_container .comments:visible").append($("<div id='disqus_thread'></div>"));
					
					if (!window.DISQUS) {
						window.disqus_shortname = 'sendage';
				        var dsq = document.createElement('script'); dsq.type = 'text/javascript'; dsq.async = true;
				        dsq.src = 'https://' + disqus_shortname + '.disqus.com/embed.js';
				        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(dsq);							
					} 
					
					var tries = 20;
					var intervalId = setInterval(function() {
						if (window.DISQUS) {
							DISQUS.reset({
							  reload: true,
							  config: function () {  
							    this.page.url = url;
							    if (!this.callbacks.onNewCommentOriginal && this.callbacks.onNewComment.length) {
									this.callbacks.onNewCommentOriginal = this.callbacks.onNewComment;
									this.callbacks.onNewComment = [];
							    }
							  }
							});
							tries = 0;
						} else {
							tries--;
						}
						if (tries <= 0) {
							clearInterval(intervalId);
						}
							
					}, 100);
				}
			};		
			return settings;	
		}
		
		function cacheMediaData(key, data)
		{
			setTimeout(function() {
				$.post("/media_cache/save", {"data[lookup]":key, "data[result]":JSON.stringify(data)});
			},0);
		}
		
		function setupLocalMedia(mediaId, href, forceTitle, container, videoRel, photoRel)
		{
			var url = window.location.protocol+'//'+window.location.host+'/media/view'+href;
			var medImage = Sendage.Config.cdn+href.replace(/-full\./,'-800.').replace(/\/img\//,'img/');
			var newElement = $("<a href='"+url+"' title='"+(forceTitle?$(forceTitle).html().replace(/'/g,""):"")+"' rel='"+photoRel+"_group' class='sendage-media-item' style='background-image:url("+Sendage.Config.cdn+href.replace(/-full\./,'-sq.').replace(/\/img\//,'img/')+");'></a>")
				.appendTo(container)
				.colorbox(getColorboxSettings(forceTitle, '<div class="image" style="background-image:url(\''+medImage+'\')"></div>', url, 'photo'));		

			attachDeleteButton(mediaId, newElement);	
		}
		
		function setupMedia(mediaId, href, forceTitle, container, videoRel, photoRel, providerURL, cache)
		{
			var providerKey = "a2214ab4d59911e0be744040d3dc5c07";
			
			if (!providerURL)
				providerURL = "https://api.embed.ly/1/oembed?key="+providerKey+"&";
			
			function handleMediaData(data)
			{
				var newElement = null;
				switch (data.type)
				{
					case "video":
					case "rich":
						var url = window.location.protocol+'//'+window.location.host+'/media/view/video/'+encodeURIComponent(encodeURIComponent(href));
						var bg = "";
						if (data.thumbnail_url) {
							bg = "style='background-image:url("+data.thumbnail_url.replace("_640","_100").replace("hqdefault","default")+");'";							
						}
						var newElement = $("<a rel='"+videoRel+"_group' class='sendage-media-item' title='"+(forceTitle?$(forceTitle).html().replace(/'/g,""):"")+"' "+bg+"></a>")
						.appendTo(container)
						.colorbox(getColorboxSettings(forceTitle, "<div class='video_container'>"+data.html+"</div>", url, 'video'));
						
						$("<button class='media-video'></button>")
						.appendTo(newElement)
						.button({
							icons: {primary: "ui-icon-play"},
							text: false						
						});
						
						break;
						
					case "photo":
						var title = forceTitle?forceTitle+" <span class='media-title-source'>Source: <a target='_blank' href='"+href+"'>"+data.provider_name+"</a></span>":"<b>"+(data.title?data.title:"Untitled")+"</b> - <a target='_blank' href='"+href+"'>"+data.provider_name+"</a>";
						newElement = $("<a href='"+data.url.replace(".jpg","_z.jpg").replace(".gif","_z.gif").replace(".png","_z.png")+"' rel='"+photoRel+"_group' class='sendage-media-item' title='"+(forceTitle?$(forceTitle).html().replace(/'/g,""):"")+"' style='background-image:url("+data.url.replace(".jpg","_s.jpg").replace(".gif","_s.gif").replace(".png","_s.png")+");'></a>")
						.appendTo(container)
						.colorbox({title: title});
						break;
				}
				attachDeleteButton(mediaId, newElement);			
			}

			if (cache)
				handleMediaData(JSON.parse(unescape(decodeURIComponent(cache))));
			else
			{
				
				$.jsonp({
					url: providerURL, 
					dataType: "jsonp",
					callbackParameter: "callback", 
					data: {maxwidth:640, url: href},
					success: function(data) {
						handleMediaData(data);
						cacheMediaData(href, data);
					},
					error: function(data) {
						var newElement = null;
	
						// only show broken image if user is able to remove it
						if (mediaId)
							newElement = $("<div class='sendage-media-item' style='background-image:url(/img/broken-photo-icon.png);'></div>").appendTo(container);
						else
							container.remove();
							
						attachDeleteButton(mediaId, newElement);
					}
				});
			}			
		}
		
		function attachDeleteButton(mediaId, newElement)
		{
			var remove = mediaId!=0&&mediaId!=undefined ? "<button class='media-remove' style='display:none;'></button>" : null;
		
			if (remove && newElement)
			{
				$(remove)
				.appendTo(newElement)
				.button({
					icons: {primary: "ui-icon-close"},
					text: false
				})
				.click(function(e) {
					e.stopImmediatePropagation();
					e.preventDefault();
					newElement.parent().fadeOut(500, function(){$(this).remove()});
					$.get("/"+controller+"/detach/"+mediaId);
				});
				
				newElement.hover(function() {
					$("button.media-remove", this).fadeIn();
				}, function() {
					$("button.media-remove", this).fadeOut();							
				})
			}		
		}		
	}		
};
